export const fonts = {
baseTitlepieceM : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
baseTitlepieceL : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
baseIngressM : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
baseIngressL : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
baseHeadlineXxs : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseHeadlineXs : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseHeadlineS : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseHeadlineM : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseHeadlineL : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
baseSubheadS : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseSubheadM : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseBodyS : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
baseBodyM : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
baseCaptionS : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseCaptionM : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseMetaXs : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseMetaS : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseMetaM : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseMetaL : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseUtilityXs : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseUtilityS : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseUtilityM : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseTabularS : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseTabularM : "\"FaktorSans\", Helvetica, Arial, sans-serif",
baseBoostM : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
baseBoostS : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
cardBody : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
cardHeadline : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
vignetteDefaultM : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
vignetteDefaultL : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
vignetteEmphasizedM : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
vignetteEmphasizedL : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
listVideoItemTitle : "\"FaktorSans\", Helvetica, Arial, sans-serif",
listVideoItemMetadata : "\"FaktorSans\", Helvetica, Arial, sans-serif",
pillM : "\"FaktorSans\", Helvetica, Arial, sans-serif",
pillBoldM : "\"FaktorSans\", Helvetica, Arial, sans-serif",
pillL : "\"FaktorSans\", Helvetica, Arial, sans-serif",
pillBoldL : "\"FaktorSans\", Helvetica, Arial, sans-serif",
titleXs : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleS : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleM : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleL : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleXl : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleXxl : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleCommercialXs : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleCommercialXxl : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleBreakingXs : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleBreakingS : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleBreakingM : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleBreakingL : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleBreakingXl : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleBreakingXxl : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleBreakingDarkXs : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleBreakingDarkS : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleBreakingDarkM : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleBreakingDarkL : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleBreakingDarkXl : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleBreakingDarkXxl : "\"FaktorTittel\", Helvetica, Arial, sans-serif",
titleFeatureXs : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleFeatureS : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleFeatureM : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleFeatureL : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleFeatureXl : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleFeatureXxl : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleFeatureDarkXs : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleFeatureDarkS : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleFeatureDarkM : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleFeatureDarkL : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleFeatureDarkXl : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleFeatureDarkXxl : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleOpinionXs : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleOpinionS : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleOpinionM : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleOpinionL : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleOpinionXl : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
titleOpinionXxl : "\"FaktorSerif\", Helvetica, Arial, sans-serif",
pretitleXs : "\"FaktorSans\", Helvetica, Arial, sans-serif",
pretitleS : "\"FaktorSans\", Helvetica, Arial, sans-serif",
pretitleM : "\"FaktorSans\", Helvetica, Arial, sans-serif",
pretitleL : "\"FaktorSans\", Helvetica, Arial, sans-serif",
pretitleXl : "\"FaktorSans\", Helvetica, Arial, sans-serif",
pretitleXxl : "\"FaktorSans\", Helvetica, Arial, sans-serif",
avatarMetaS : "\"FaktorSans\", Helvetica, Arial, sans-serif",
avatarMetaM : "\"FaktorSans\", Helvetica, Arial, sans-serif",
avatarMetaL : "\"FaktorSans\", Helvetica, Arial, sans-serif",
groupTitleM : "\"FaktorSans\", Helvetica, Arial, sans-serif",
groupTitleL : "\"FaktorSans\", Helvetica, Arial, sans-serif",
adLabelM : "\"FaktorSans\", Helvetica, Arial, sans-serif",
valgomatHeadline1M : "\"National2\", Helvetica, Arial, sans-serif",
valgomatHeadline1L : "\"National2\", Helvetica, Arial, sans-serif",
valgomatHeadline2M : "\"National2\", Helvetica, Arial, sans-serif",
valgomatHeadline2L : "\"National2\", Helvetica, Arial, sans-serif",
valgomatQuestionM : "\"National2\", Helvetica, Arial, sans-serif",
valgomatQuestionL : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailXs : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailS : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailM : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailL : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailXl : "\"National2\", Helvetica, Arial, sans-serif",
valgomatUtilityM : "\"National2\", Helvetica, Arial, sans-serif",
tipusM : "\"National2\", Helvetica, Arial, sans-serif",
toggleUtilityM : "\"FaktorSans\", Helvetica, Arial, sans-serif",
}

export const fontSizes = {
baseTitlepieceM : "2rem",
baseTitlepieceL : "4.375rem",
baseIngressM : "1.625rem",
baseIngressL : "2.4375rem",
baseHeadlineXxs : "0.875rem",
baseHeadlineXs : "1rem",
baseHeadlineS : "1.125rem",
baseHeadlineM : "1.5rem",
baseHeadlineL : "1.75rem",
baseSubheadS : "1rem",
baseSubheadM : "1.25rem",
baseBodyS : "1rem",
baseBodyM : "1.25rem",
baseCaptionS : "1rem",
baseCaptionM : "1.125rem",
baseMetaXs : "0.625rem",
baseMetaS : "0.8125rem",
baseMetaM : "0.875rem",
baseMetaL : "1rem",
baseUtilityXs : "0.875rem",
baseUtilityS : "1rem",
baseUtilityM : "1.125rem",
baseTabularS : "1rem",
baseTabularM : "1.125rem",
baseBoostM : "4.0625rem",
baseBoostS : "1.4375rem",
cardBody : "1.375rem",
cardHeadline : "2.25rem",
vignetteDefaultM : "1.25rem",
vignetteDefaultL : "1.375rem",
vignetteEmphasizedM : "1.25rem",
vignetteEmphasizedL : "1.375rem",
listVideoItemTitle : "1rem",
listVideoItemMetadata : "0.875rem",
pillM : "0.8125rem",
pillBoldM : "0.8125rem",
pillL : "0.9375rem",
pillBoldL : "0.9375rem",
titleXs : "1.0625rem",
titleS : "1.16875rem",
titleM : "1.625rem",
titleL : "1.875rem",
titleXl : "3.75rem",
titleXxl : "4.0625rem",
titleCommercialXs : "1.4375rem",
titleCommercialXxl : "2.75rem",
titleBreakingXs : "1.4375rem",
titleBreakingS : "1.3125rem",
titleBreakingM : "1.875rem",
titleBreakingL : "2.1875rem",
titleBreakingXl : "3.75rem",
titleBreakingXxl : "4.0625rem",
titleBreakingDarkXs : "1.3125rem",
titleBreakingDarkS : "1.3125rem",
titleBreakingDarkM : "1.875rem",
titleBreakingDarkL : "2.5rem",
titleBreakingDarkXl : "3.125rem",
titleBreakingDarkXxl : "3.4375rem",
titleFeatureXs : "1.3125rem",
titleFeatureS : "1.3125rem",
titleFeatureM : "1.875rem",
titleFeatureL : "2.1875rem",
titleFeatureXl : "3.4375rem",
titleFeatureXxl : "3.75rem",
titleFeatureDarkXs : "1.3125rem",
titleFeatureDarkS : "1.3125rem",
titleFeatureDarkM : "1.875rem",
titleFeatureDarkL : "2.5rem",
titleFeatureDarkXl : "3.125rem",
titleFeatureDarkXxl : "3.75rem",
titleOpinionXs : "1.0625rem",
titleOpinionS : "1.4375rem",
titleOpinionM : "2.1875rem",
titleOpinionL : "2.1875rem",
titleOpinionXl : "3.125rem",
titleOpinionXxl : "3.4375rem",
pretitleXs : "1rem",
pretitleS : "1rem",
pretitleM : "1.125rem",
pretitleL : "1.25rem",
pretitleXl : "1.25rem",
pretitleXxl : "1.25rem",
avatarMetaS : "0.8125rem",
avatarMetaM : "0.9375rem",
avatarMetaL : "1.125rem",
groupTitleM : "1.25rem",
groupTitleL : "1.25rem",
adLabelM : "0.9375rem",
valgomatHeadline1M : "2rem",
valgomatHeadline1L : "2.25rem",
valgomatHeadline2M : "1.25rem",
valgomatHeadline2L : "1.5rem",
valgomatQuestionM : "1.125rem",
valgomatQuestionL : "1.5rem",
valgomatDetailXs : "0.875rem",
valgomatDetailS : "1rem",
valgomatDetailM : "1.25rem",
valgomatDetailL : "1.5rem",
valgomatDetailXl : "2.25rem",
valgomatUtilityM : "1.125rem",
tipusM : "1.125rem",
toggleUtilityM : "1.125rem",
}

export const fontWeights = {
baseTitlepieceM : 900,
baseTitlepieceL : 900,
baseIngressM : 900,
baseIngressL : 900,
baseHeadlineXxs : 700,
baseHeadlineXs : 700,
baseHeadlineS : 700,
baseHeadlineM : 700,
baseHeadlineL : 900,
baseSubheadS : 700,
baseSubheadM : 700,
baseBodyS : 400,
baseBodyM : 400,
baseCaptionS : 400,
baseCaptionM : 400,
baseMetaXs : 400,
baseMetaS : 400,
baseMetaM : 400,
baseMetaL : 400,
baseUtilityXs : 400,
baseUtilityS : 400,
baseUtilityM : 400,
baseTabularS : 400,
baseTabularM : 400,
baseBoostM : 900,
baseBoostS : 900,
cardBody : 400,
cardHeadline : 900,
vignetteDefaultM : 900,
vignetteDefaultL : 900,
vignetteEmphasizedM : 900,
vignetteEmphasizedL : 900,
listVideoItemTitle : 700,
listVideoItemMetadata : 400,
pillM : 400,
pillBoldM : 700,
pillL : 400,
pillBoldL : 700,
titleXs : 900,
titleS : 900,
titleM : 900,
titleL : 900,
titleXl : 900,
titleXxl : 900,
titleCommercialXs : 900,
titleCommercialXxl : 900,
titleBreakingXs : 900,
titleBreakingS : 900,
titleBreakingM : 900,
titleBreakingL : 900,
titleBreakingXl : 900,
titleBreakingXxl : 900,
titleBreakingDarkXs : 900,
titleBreakingDarkS : 900,
titleBreakingDarkM : 900,
titleBreakingDarkL : 900,
titleBreakingDarkXl : 900,
titleBreakingDarkXxl : 900,
titleFeatureXs : 700,
titleFeatureS : 700,
titleFeatureM : 700,
titleFeatureL : 700,
titleFeatureXl : 700,
titleFeatureXxl : 700,
titleFeatureDarkXs : 700,
titleFeatureDarkS : 700,
titleFeatureDarkM : 700,
titleFeatureDarkL : 700,
titleFeatureDarkXl : 700,
titleFeatureDarkXxl : 700,
titleOpinionXs : 700,
titleOpinionS : 700,
titleOpinionM : 700,
titleOpinionL : 700,
titleOpinionXl : 700,
titleOpinionXxl : 700,
pretitleXs : 400,
pretitleS : 400,
pretitleM : 400,
pretitleL : 400,
pretitleXl : 400,
pretitleXxl : 400,
avatarMetaS : 700,
avatarMetaM : 700,
avatarMetaL : 700,
groupTitleM : 700,
groupTitleL : 700,
adLabelM : 700,
valgomatHeadline1M : 400,
valgomatHeadline1L : 400,
valgomatHeadline2M : 400,
valgomatHeadline2L : 400,
valgomatQuestionM : 400,
valgomatQuestionL : 400,
valgomatDetailXs : 400,
valgomatDetailS : 400,
valgomatDetailM : 400,
valgomatDetailL : 400,
valgomatDetailXl : 500,
valgomatUtilityM : 400,
tipusM : 400,
toggleUtilityM : 400,
}

export const letterSpacings ={
baseTitlepieceM : "0rem",
baseTitlepieceL : "0rem",
baseIngressM : "0rem",
baseIngressL : "0rem",
baseHeadlineXxs : "0rem",
baseHeadlineXs : "0rem",
baseHeadlineS : "0rem",
baseHeadlineM : "0rem",
baseHeadlineL : "0rem",
baseSubheadS : "0rem",
baseSubheadM : "0rem",
baseBodyS : "0rem",
baseBodyM : "0rem",
baseCaptionS : "0rem",
baseCaptionM : "0rem",
baseMetaXs : "0rem",
baseMetaS : "0rem",
baseMetaM : "0rem",
baseMetaL : "0rem",
baseUtilityXs : "0rem",
baseUtilityS : "0rem",
baseUtilityM : "0rem",
baseTabularS : "0rem",
baseTabularM : "0rem",
baseBoostM : "0rem",
baseBoostS : "0rem",
cardBody : "0rem",
cardHeadline : "0rem",
vignetteDefaultM : "0rem",
vignetteDefaultL : "0rem",
vignetteEmphasizedM : "0rem",
vignetteEmphasizedL : "0rem",
listVideoItemTitle : "-0.0199252825102718rem",
listVideoItemMetadata : "-0.0199252825102718rem",
pillM : "-0.0099626412551359rem",
pillBoldM : "0rem",
pillL : "-0.0099626412551359rem",
pillBoldL : "0rem",
titleXs : "0rem",
titleS : "0rem",
titleM : "0rem",
titleL : "0rem",
titleXl : "0rem",
titleXxl : "0rem",
titleCommercialXs : "0rem",
titleCommercialXxl : "0rem",
titleBreakingXs : "0rem",
titleBreakingS : "0rem",
titleBreakingM : "0rem",
titleBreakingL : "0rem",
titleBreakingXl : "0rem",
titleBreakingXxl : "0rem",
titleBreakingDarkXs : "0rem",
titleBreakingDarkS : "0rem",
titleBreakingDarkM : "0rem",
titleBreakingDarkL : "0rem",
titleBreakingDarkXl : "0rem",
titleBreakingDarkXxl : "0rem",
titleFeatureXs : "-0.0298879237654077rem",
titleFeatureS : "-0.0298879237654077rem",
titleFeatureM : "-0.0298879237654077rem",
titleFeatureL : "-0.0298879237654077rem",
titleFeatureXl : "-0.0298879237654077rem",
titleFeatureXxl : "-0.0298879237654077rem",
titleFeatureDarkXs : "-0.0298879237654077rem",
titleFeatureDarkS : "-0.0298879237654077rem",
titleFeatureDarkM : "-0.0298879237654077rem",
titleFeatureDarkL : "-0.0298879237654077rem",
titleFeatureDarkXl : "-0.0298879237654077rem",
titleFeatureDarkXxl : "-0.0298879237654077rem",
titleOpinionXs : "-0.0099626412551359rem",
titleOpinionS : "-0.0099626412551359rem",
titleOpinionM : "-0.0099626412551359rem",
titleOpinionL : "-0.0099626412551359rem",
titleOpinionXl : "-0.0099626412551359rem",
titleOpinionXxl : "-0.0099626412551359rem",
pretitleXs : "-0.0099626412551359rem",
pretitleS : "-0.0099626412551359rem",
pretitleM : "-0.0099626412551359rem",
pretitleL : "-0.0099626412551359rem",
pretitleXl : "-0.0099626412551359rem",
pretitleXxl : "-0.0099626412551359rem",
avatarMetaS : "",
avatarMetaM : "",
avatarMetaL : "",
groupTitleM : "-0.0099626412551359rem",
groupTitleL : "-0.0099626412551359rem",
adLabelM : "0rem",
valgomatHeadline1M : "0rem",
valgomatHeadline1L : "0rem",
valgomatHeadline2M : "",
valgomatHeadline2L : "",
valgomatQuestionM : "",
valgomatQuestionL : "",
valgomatDetailXs : "",
valgomatDetailS : "",
valgomatDetailM : "",
valgomatDetailL : "",
valgomatDetailXl : "",
tipusM : "-0.0099626412551359rem",
toggleUtilityM : "0rem",
}

export const lineHeights = {
baseTitlepieceM : "1.15",
baseTitlepieceL : "1.15",
baseIngressM : "1.2",
baseIngressL : "1.2",
baseHeadlineXxs : "1.1",
baseHeadlineXs : "1.1",
baseHeadlineS : "1.2",
baseHeadlineM : "1.2",
baseHeadlineL : "1.2",
baseSubheadS : "1.2",
baseSubheadM : "1.2",
baseBodyS : "1.2",
baseBodyM : "1.2",
baseCaptionS : "1.4",
baseCaptionM : "1.4",
baseMetaXs : "Auto",
baseMetaS : "Auto",
baseMetaM : "Auto",
baseMetaL : "Auto",
baseUtilityXs : "1.5",
baseUtilityS : "1.25",
baseUtilityM : "1.25",
baseTabularS : "Auto",
baseTabularM : "Auto",
baseBoostM : "1.1",
baseBoostS : "1.1",
cardBody : "1.5",
cardHeadline : "1.2",
vignetteDefaultM : "1.15",
vignetteDefaultL : "1.15",
vignetteEmphasizedM : "1.15",
vignetteEmphasizedL : "1.15",
listVideoItemTitle : "1.4",
listVideoItemMetadata : "1.4",
pillM : "1.2",
pillBoldM : "1.2",
pillL : "1.2",
pillBoldL : "1.2",
titleXs : "1.1",
titleS : "1.1",
titleM : "1.1",
titleL : "1.1",
titleXl : "1.1",
titleXxl : "1.1",
titleCommercialXs : "1.1",
titleCommercialXxl : "1.1",
titleBreakingXs : "1.05",
titleBreakingS : "1.05",
titleBreakingM : "1.05",
titleBreakingL : "1.05",
titleBreakingXl : "1.05",
titleBreakingXxl : "1.05",
titleBreakingDarkXs : "1.05",
titleBreakingDarkS : "1.05",
titleBreakingDarkM : "1.05",
titleBreakingDarkL : "1.05",
titleBreakingDarkXl : "1.05",
titleBreakingDarkXxl : "1.05",
titleFeatureXs : "1",
titleFeatureS : "1",
titleFeatureM : "1",
titleFeatureL : "1",
titleFeatureXl : "1",
titleFeatureXxl : "1",
titleFeatureDarkXs : "1",
titleFeatureDarkS : "1",
titleFeatureDarkM : "1",
titleFeatureDarkL : "1",
titleFeatureDarkXl : "1",
titleFeatureDarkXxl : "1",
titleOpinionXs : "1.1",
titleOpinionS : "1.1",
titleOpinionM : "1.1",
titleOpinionL : "1.1",
titleOpinionXl : "1.1",
titleOpinionXxl : "1.1",
pretitleXs : "1.1",
pretitleS : "1.1",
pretitleM : "1.1",
pretitleL : "1.1",
pretitleXl : "1.1",
pretitleXxl : "1.1",
avatarMetaS : "1.05",
avatarMetaM : "1.05",
avatarMetaL : "1.05",
groupTitleM : "1.2",
groupTitleL : "1.2",
valgomatHeadline1M : "1.25",
valgomatHeadline1L : "1.25",
valgomatHeadline2M : "1.5",
valgomatHeadline2L : "1.5",
valgomatQuestionM : "1.5",
valgomatQuestionL : "1.5",
valgomatDetailXs : "1.5",
valgomatDetailS : "1.5",
valgomatDetailM : "1.5",
valgomatDetailL : "1.5",
valgomatDetailXl : "1.5",
valgomatUtilityM : "1.5",
tipusM : "1.1",
toggleUtilityM : "1.25",
}
