/**
 * Autogenerated file.
 * Built by @amedia/brick-tokens
 */

export const premiumIconM = "0.6875rem";
export const premiumIconL = "0.6875rem";
export const avatarS = "2.5rem";
export const avatarM = "3.125rem";
export const avatarL = "4.375rem";
