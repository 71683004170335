/**
 * Autogenerated file.
 * Built by @amedia/brick-tokens
 */

export const leverNone = "0px";
export const leverS = "2px";
export const leverM = "4px";
export const leverL = "8px";
export const leverXl = "16px";
export const leverMax = "9999px";
export const pill = "0px";
export const teaserCarousel = "0px";
export const avatar = "9999px";
export const groupTeaserImage = "0px";
export const baseNone = "0px";
export const baseS = "2px";
export const baseM = "4px";
export const baseL = "8px";
export const baseXl = "16px";
export const baseMax = "9999px";
export const card = "0px";
export const teaser = "0px";
export const teaserImage = "0px";
export const button = "9999px";
export const premiumIcon = "2px";
export const valgomat = "8px";
