/**
 * Autogenerated file.
 * Built by @amedia/brick-tokens
 */

export const baseNone = "0px";
export const baseS = "1px";
export const baseM = "2px";
export const baseL = "3px";
export const focusOutline = "2px";
export const outline = "3px";
export const button = "2px";
export const valgomat = "1px";
export const card = "2px";
