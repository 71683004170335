// typescript leaves import/exports statements alone when compiling, so
// we need to import from the compiled file which is .js, even though we
// have .ts on the filesystem.
//
// more here: https://devblogs.microsoft.com/typescript/announcing-typescript-4-7/#esm-nodejs

import { designNameToClassName } from '@amedia/brick-classnames';

import * as theme from '../../build/style-dictionary/alt/index.js';
import { createCustomTheme } from '../createCustomeTheme.js';
import { createTheme } from '../../src/stitches.js';

export const alt = {
  colors: theme.colors,
  fonts: theme.fonts,
  fontSizes: theme.fontSizes,
  fontWeights: theme.fontWeights,
  lineHeights: theme.lineHeights,
  letterSpacings: theme.letterSpacings,
  space: theme.space,
  shadows: theme.shadows,
  radii: theme.radii,
  borderWidths: theme.borderWidths,
  sizes: { ...theme.space, ...theme.sizes },
  other: theme.other,
};

export const altTheme = createTheme(
  designNameToClassName('alt'),
  createCustomTheme(alt)
);
