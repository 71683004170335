import * as theme from '../../build/style-dictionary/simple/index';
import { createCustomTheme } from '../createCustomeTheme.js';
import { createTheme } from '../../src/stitches.js';

const simpleConfig = {
  colors: theme.colors,
  fonts: theme.fonts,
  fontSizes: theme.fontSizes,
  fontWeights: theme.fontWeights,
  lineHeights: theme.lineHeights,
  letterSpacings: theme.letterSpacings,
  space: theme.space,
  shadows: theme.shadows,
  radii: theme.radii,
  borderWidths: theme.borderWidths,
  sizes: { ...theme.space, ...theme.sizes },
  other: theme.other,
};

const simpleCustomTheme = createCustomTheme(simpleConfig);

export type Theme = typeof simpleCustomTheme;

export const simpleTheme = createTheme('themeSimple', simpleCustomTheme);
