// typescript leaves import/exports statements alone when compiling, so
// we need to import from the compiled file which is .js, even though we
// have .ts on the filesystem.
//
// more here: https://devblogs.microsoft.com/typescript/announcing-typescript-4-7/#esm-nodejs
import { designNameToClassName } from '@amedia/brick-classnames';

import * as theme from '../../build/style-dictionary/charlie/index.js';
import { createCustomTheme } from '../createCustomeTheme.js';
import { createTheme } from '../../src/stitches.js';

const charlieConfig = {
  colors: theme.colors,
  fonts: theme.fonts,
  fontSizes: {
    ...theme.fontSizes,
    titleCqiBase: '0.625rem',
    titleFeatureCqiBase: '0.875rem',
    titleOpinionCqiBase: '0.875rem',
  },
  fontWeights: theme.fontWeights,
  lineHeights: theme.lineHeights,
  letterSpacings: theme.letterSpacings,
  space: theme.space,
  shadows: theme.shadows,
  radii: theme.radii,
  borderWidths: theme.borderWidths,
  sizes: {
    ...theme.space,
    ...theme.sizes,
    titleCqi: '4cqi',
    titleFeatureCqi: '4cqi',
    titleOpinionCqi: '4.6cqi',
    titleBreakingCqi: '4.5cqi',
    titleCommercialCqi: '4cqi',
    titleCqh: '1.8cqh',
    titleFeatureCqh: '1.8cqh',
    titleOpinionCqh: '1.6cqh',
    titleBreakingCqh: '1.8cqh',
    titleCommercialCqh: '1.5cqh',

    //tokens below is no longer in use from brick-tokens 5.15.0. Remove when we have implemented this token version in laserbeak and foxfield (not used by scorponok)
    letterCountSCqi: '3cqi',
    letterCountMCqi: '1.2cqi',
    letterCountOpinionSCqi: '3cqi',
    letterCountOpinionMCqi: '0.7cqi',
    letterCountFeatureSCqi: '3cqi',
    letterCountFeatureMCqi: '2cqi',
    letterCountBreakingSCqi: '1.2cqi',
    letterCountBreakingMCqi: '1.6cqi',
    letterCountCommercialSCqi: '2.2cqi',
    letterCountCommercialMCqi: '0.7cqi',
    letterCountSingleWordSCqi: '1.25cqi',
    letterCountSingleWordMCqi: '1.8cqi',
    //end deprecated tokens
  },
  other: theme.other,
};

export const charlieTheme = createTheme(
  designNameToClassName('charlie'),
  createCustomTheme(charlieConfig)
);
