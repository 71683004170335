/**
 * Autogenerated file.
 * Built by @amedia/brick-tokens
 */

export const boxShadowTeaserCarousel = "0px 0px 0px 0px #00000000";
export const boxShadowBaseNone = "0px 0px 0px 0px #00000000";
export const boxShadowBaseLow = "0.5px 1px 1px 0 #00000026";
export const boxShadowBaseMedium = "1px 2px 4px 0px #0000001a, 2px 4px 8px 0px #00000017";
export const boxShadowBaseHigh = "1px 2px 8px 0px #0000000f, 2px 4px 12px 0px #0000000d, 4px 8px 20px 0px #0000000a, 6px 12px 28px 0px #00000008";
export const boxShadowTeaser = "0px 0px 0px 0px #00000000";
