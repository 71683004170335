import { globalCss } from './stitches';

export const normalize = globalCss({
  ':where(html)': {
    webKitTextSizeAdjust: 'none',
    blockSize: '100%',
    colorScheme: 'light',
  },
  '@media (prefers-color-scheme: dark)': {
    ':where(html)': {
      colorScheme: 'dark',
    },
  },
  '*, :after, :before': {
    boxSizing: 'border-box',
  },
  ':where(:not(dialog))': {
    margin: 0,
  },
  ':where(:not(fieldset, progress, meter))': {
    backgroundOrigin: 'border-box',
    backgroundRepeat: 'no-repeat',
    borderStyle: 'solid',
    borderWidth: 0,
  },
  '@media (prefers-reduced-motion: no-preference)': {
    ':where(html)': {
      scrollBehavior: 'smooth',
    },
    ':focus-visible': {
      transition: 'outline-offset 145ms 130ms',
    },
    ':where(:not(:active)):focus-visible': {
      transitionDuration: '0.25s',
    },
  },
  ':where(body)': {
    scrollbarGutter: 'stable both-edges',
    minBlockSize: '100%',
  },
  ':where(a, u, ins, abbr)': {
    textUnderlineOffset: '1px',
  },
  ':where(a[href], area, button, input, label[for], select, summary, textarea, [tabindex]:not([tabindex*="-"]))':
    {
      cursor: 'pointer',
      touchAction: 'manipulation',
    },
  ':where(img, svg, video, canvas, audio, iframe, embed, object)': {
    display: 'block',
  },
  ':where(img, svg, video)': {
    blockSize: 'auto',
    maxInlineSize: '100%',
  },
  ':where(input, button, textarea, select), :where(input[type="file"])::-webkit-file-upload-button':
    {
      color: 'inherit',
      font: 'inherit',
      fontSize: 'inherit',
      letterSpacing: 'inherit',
    },
  ':where(textarea)': {
    resize: 'block',
  },
  ':where(svg)': {
    stroke: 'none',
    fill: 'currentColor',
  },
  ':where(svg):where(:not([fill]))': {
    stroke: 'currentColor',
    fill: 'none',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },
  ':where(:not(pre) > code, kbd)': {
    whiteSpace: 'nowrap',
  },
  ':where(pre)': {
    maxInlineSize: 'max-content',
    minInlineSize: 0,
    whiteSpace: 'pre',
  },
  ':where(dialog)::backdrop': {
    backdropFilter: 'blur(25px)',
  },
  ':where(html[:has(dialog[open])])': {
    overflow: 'hidden',
  },
  ':where(html:has(dialog[open]))': {
    overflow: 'hidden',
  },
  '@media (pointer: coarse) and (any-hover: none)': {
    '*:hover, *:hover::before, *:hover::after': {
      backgroundColor: 'inherit',
      color: 'inherit',
      outline: 'inherit',
      border: 'inherit',
    },
  },
});
