/**
 * Autogenerated file.
 * Built by @amedia/brick-tokens
 */

export const baseNone = "0px";
export const baseS = "2px";
export const baseM = "4px";
export const baseL = "8px";
export const baseXl = "16px";
export const baseMax = "9999px";
export const card = "0px";
export const teaser = "0px";
export const avatar = "0px";
export const teaserImage = "0px";
export const button = "9999px";
export const pill = "2px";
export const premiumIcon = "2px";
export const valgomat = "8px";
export const teaserCarousel = "4px";
export const groupTeaserImage = "2px";
