export const backgroundColors = {
  black: {
    backgroundColor: '$supportiveBlackBg',
    color: '$supportiveBlackFg',
  },
  yellow: {
    backgroundColor: '$supportiveHighlightBg',
    color: '$supportiveHighlightFg',
  },
  highlight: {
    backgroundColor: '$supportiveHighlightBg',
    color: '$supportiveHighlightFg',
  },
  customOne: {
    backgroundColor:
      'var(--custom-background-color-one, $supportiveCustomOneBg)',
    color: 'var(--custom-background-color-one-front, $supportiveCustomOneFg)',
  },
  customTwo: {
    backgroundColor:
      'var(--custom-background-color-two, $supportiveCustomTwoBg)',
    color: 'var(--custom-background-color-two-front, $supportiveCustomTwoFg)',
  },
  customThree: {
    backgroundColor:
      'var(--custom-background-color-three, $supportiveCustomThreeBg)',
    color:
      'var(--custom-background-color-three-front, $supportiveCustomThreeFg)',
  },
  sport: {
    backgroundColor: '$supportiveSportBg',
    color: '$supportiveSportFg',
  },
  opinion: {
    backgroundColor: 'var(--opinion-background-color, $supportiveOpinionBg)',
    color: 'var(--opinion-color-front, $supportiveOpinionFg)',
  },
  finance: {
    backgroundColor: '$supportiveFinanceBg',
    color: '$supportiveFinanceFg',
  },
};
