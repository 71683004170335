export const fonts = {
baseTitlepieceM : "Tiempos Headline",
baseTitlepieceL : "Tiempos Headline",
baseBoostS : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
baseBoostM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
baseIngressM : "\"National2\", Helvetica, Arial, sans-serif",
baseIngressL : "\"National2\", Helvetica, Arial, sans-serif",
baseHeadlineXxs : "Tiempos Headline",
baseHeadlineXs : "Tiempos Headline",
baseHeadlineS : "Tiempos Headline",
baseHeadlineM : "Tiempos Headline",
baseHeadlineL : "Tiempos Headline",
baseSubheadS : "\"National2\", Helvetica, Arial, sans-serif",
baseSubheadM : "\"National2\", Helvetica, Arial, sans-serif",
baseBodyS : "\"National2\", Helvetica, Arial, sans-serif",
baseBodyM : "\"National2\", Helvetica, Arial, sans-serif",
baseCaptionS : "\"National2\", Helvetica, Arial, sans-serif",
baseCaptionM : "\"National2\", Helvetica, Arial, sans-serif",
baseMetaXs : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
baseMetaS : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
baseMetaM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
baseMetaL : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
baseUtilityXs : "\"National2\", Helvetica, Arial, sans-serif",
baseUtilityS : "\"National2\", Helvetica, Arial, sans-serif",
baseUtilityM : "\"National2\", Helvetica, Arial, sans-serif",
baseTabularS : "\"National2\", Helvetica, Arial, sans-serif",
baseTabularM : "\"National2\", Helvetica, Arial, sans-serif",
titleXs : "Tiempos Headline",
titleS : "Tiempos Headline",
titleM : "Tiempos Headline",
titleL : "Tiempos Headline",
titleXl : "Tiempos Headline",
titleXxl : "Tiempos Headline",
titleCommercialXs : "\"Merriweather Sans\", Helvetica, Arial, sans-serif",
titleCommercialXxl : "\"Merriweather Sans\", Helvetica, Arial, sans-serif",
titleBreakingXs : "Tiempos Headline",
titleBreakingS : "Tiempos Headline",
titleBreakingM : "Tiempos Headline",
titleBreakingL : "Tiempos Headline",
titleBreakingXl : "Tiempos Headline",
titleBreakingXxl : "Tiempos Headline",
titleBreakingDarkXs : "Tiempos Headline",
titleBreakingDarkS : "Tiempos Headline",
titleBreakingDarkM : "Tiempos Headline",
titleBreakingDarkL : "Tiempos Headline",
titleBreakingDarkXl : "Tiempos Headline",
titleBreakingDarkXxl : "Tiempos Headline",
pretitleXs : "Tiempos Text",
pretitleS : "Tiempos Text",
pretitleM : "Tiempos Text",
pretitleL : "Tiempos Text",
pretitleXl : "Tiempos Text",
pretitleXxl : "Tiempos Text",
pillM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
pillBoldM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
pillL : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
pillBoldL : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleFeatureXs : "Tiempos Headline",
titleFeatureS : "Tiempos Headline",
titleFeatureM : "Tiempos Headline",
titleFeatureL : "Tiempos Headline",
titleFeatureXl : "Tiempos Headline",
titleFeatureXxl : "Tiempos Headline",
titleFeatureDarkXs : "Tiempos Headline",
titleFeatureDarkS : "Tiempos Headline",
titleFeatureDarkM : "Tiempos Headline",
titleFeatureDarkL : "Tiempos Headline",
titleFeatureDarkXl : "Tiempos Headline",
titleFeatureDarkXxl : "Tiempos Headline",
titleOpinionXs : "Tiempos Text",
titleOpinionS : "Tiempos Text",
titleOpinionM : "Tiempos Text",
titleOpinionL : "Tiempos Text",
titleOpinionXl : "Tiempos Text",
titleOpinionXxl : "Tiempos Text",
tipusM : "Tiempos Text",
avatarMetaS : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
avatarMetaM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
avatarMetaL : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
groupTitleM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
groupTitleL : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
valgomatHeadline1M : "\"National2\", Helvetica, Arial, sans-serif",
valgomatHeadline1L : "\"National2\", Helvetica, Arial, sans-serif",
valgomatHeadline2M : "\"National2\", Helvetica, Arial, sans-serif",
valgomatHeadline2L : "\"National2\", Helvetica, Arial, sans-serif",
valgomatQuestionM : "\"National2\", Helvetica, Arial, sans-serif",
valgomatQuestionL : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailXs : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailS : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailM : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailL : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailXl : "\"National2\", Helvetica, Arial, sans-serif",
valgomatUtilityM : "\"National2\", Helvetica, Arial, sans-serif",
adLabelM : "\"National2\", Helvetica, Arial, sans-serif",
toggleUtilityM : "\"National2\", Helvetica, Arial, sans-serif",
}

export const fontSizes = {
baseTitlepieceM : "2rem",
baseTitlepieceL : "4.375rem",
baseBoostS : "1.3125rem",
baseBoostM : "3.125rem",
baseIngressM : "1.5rem",
baseIngressL : "1.875rem",
baseHeadlineXxs : "0.875rem",
baseHeadlineXs : "1rem",
baseHeadlineS : "1.125rem",
baseHeadlineM : "1.3125rem",
baseHeadlineL : "1.75rem",
baseSubheadS : "1rem",
baseSubheadM : "1.125rem",
baseBodyS : "1rem",
baseBodyM : "1.125rem",
baseCaptionS : "0.875rem",
baseCaptionM : "1rem",
baseMetaXs : "0.8125rem",
baseMetaS : "0.9375rem",
baseMetaM : "1rem",
baseMetaL : "1.125rem",
baseUtilityXs : "0.9375rem",
baseUtilityS : "1.0625rem",
baseUtilityM : "1.125rem",
baseTabularS : "1rem",
baseTabularM : "1.125rem",
titleXs : "1.125rem",
titleS : "1.75rem",
titleM : "2.5rem",
titleL : "2.25rem",
titleXl : "2.8125rem",
titleXxl : "3.75rem",
titleCommercialXs : "1.375rem",
titleCommercialXxl : "2.375rem",
titleBreakingXs : "1.25rem",
titleBreakingS : "1.75rem",
titleBreakingM : "2.5rem",
titleBreakingL : "2.25rem",
titleBreakingXl : "3.125rem",
titleBreakingXxl : "3.75rem",
titleBreakingDarkXs : "1.75rem",
titleBreakingDarkS : "1.75rem",
titleBreakingDarkM : "2.5rem",
titleBreakingDarkL : "2.25rem",
titleBreakingDarkXl : "3.75rem",
titleBreakingDarkXxl : "4.0625rem",
pretitleXs : "1.125rem",
pretitleS : "1.125rem",
pretitleM : "1.125rem",
pretitleL : "1.25rem",
pretitleXl : "1.25rem",
pretitleXxl : "1.25rem",
pillM : "0.9375rem",
pillBoldM : "0.9375rem",
pillL : "0.9375rem",
pillBoldL : "0.9375rem",
titleFeatureXs : "1.3125rem",
titleFeatureS : "1.3125rem",
titleFeatureM : "1.875rem",
titleFeatureL : "2.1875rem",
titleFeatureXl : "3.4375rem",
titleFeatureXxl : "3.75rem",
titleFeatureDarkXs : "1.3125rem",
titleFeatureDarkS : "1.3125rem",
titleFeatureDarkM : "1.875rem",
titleFeatureDarkL : "2.5rem",
titleFeatureDarkXl : "3.125rem",
titleFeatureDarkXxl : "3.75rem",
titleOpinionXs : "1rem",
titleOpinionS : "1.375rem",
titleOpinionM : "1.75rem",
titleOpinionL : "2rem",
titleOpinionXl : "3.125rem",
titleOpinionXxl : "3.125rem",
tipusM : "1rem",
avatarMetaS : "0.9375rem",
avatarMetaM : "1rem",
avatarMetaL : "1.125rem",
groupTitleM : "1.375rem",
groupTitleL : "1.75rem",
valgomatHeadline1M : "2rem",
valgomatHeadline1L : "2.25rem",
valgomatHeadline2M : "1.25rem",
valgomatHeadline2L : "1.5rem",
valgomatQuestionM : "1.125rem",
valgomatQuestionL : "1.5rem",
valgomatDetailXs : "0.875rem",
valgomatDetailS : "1rem",
valgomatDetailM : "1.25rem",
valgomatDetailL : "1.5rem",
valgomatDetailXl : "2.25rem",
valgomatUtilityM : "1.125rem",
adLabelM : "0.8125rem",
toggleUtilityM : "1.125rem",
}

export const fontWeights = {
baseTitlepieceM : 700,
baseTitlepieceL : 700,
baseBoostS : 700,
baseBoostM : 700,
baseIngressM : 400,
baseIngressL : 400,
baseHeadlineXxs : 700,
baseHeadlineXs : 700,
baseHeadlineS : 700,
baseHeadlineM : 700,
baseHeadlineL : 700,
baseSubheadS : 700,
baseSubheadM : 400,
baseBodyS : 400,
baseBodyM : 400,
baseCaptionS : 400,
baseCaptionM : 400,
baseMetaXs : 400,
baseMetaS : 400,
baseMetaM : 400,
baseMetaL : 400,
baseUtilityXs : 400,
baseUtilityS : 400,
baseUtilityM : 400,
baseTabularS : 400,
baseTabularM : 400,
titleXs : 400,
titleS : 400,
titleM : 400,
titleL : 400,
titleXl : 400,
titleXxl : 400,
titleCommercialXs : 600,
titleCommercialXxl : 600,
titleBreakingXs : 700,
titleBreakingS : 700,
titleBreakingM : 700,
titleBreakingL : 700,
titleBreakingXl : 700,
titleBreakingXxl : 700,
titleBreakingDarkXs : 700,
titleBreakingDarkS : 700,
titleBreakingDarkM : 700,
titleBreakingDarkL : 700,
titleBreakingDarkXl : 700,
titleBreakingDarkXxl : 700,
pretitleXs : 500,
pretitleS : 500,
pretitleM : 500,
pretitleL : 500,
pretitleXl : 500,
pretitleXxl : 500,
pillM : 400,
pillBoldM : 700,
pillL : 400,
pillBoldL : 700,
titleFeatureXs : 400,
titleFeatureS : 400,
titleFeatureM : 400,
titleFeatureL : 400,
titleFeatureXl : 400,
titleFeatureXxl : 400,
titleFeatureDarkXs : 400,
titleFeatureDarkS : 400,
titleFeatureDarkM : 400,
titleFeatureDarkL : 400,
titleFeatureDarkXl : 400,
titleFeatureDarkXxl : 400,
titleOpinionXs : 500,
titleOpinionS : 500,
titleOpinionM : 500,
titleOpinionL : 500,
titleOpinionXl : 500,
titleOpinionXxl : 500,
tipusM : 500,
avatarMetaS : 700,
avatarMetaM : 700,
avatarMetaL : 700,
groupTitleM : 700,
groupTitleL : 700,
valgomatHeadline1M : 400,
valgomatHeadline1L : 400,
valgomatHeadline2M : 400,
valgomatHeadline2L : 400,
valgomatQuestionM : 400,
valgomatQuestionL : 400,
valgomatDetailXs : 400,
valgomatDetailS : 400,
valgomatDetailM : 400,
valgomatDetailL : 400,
valgomatDetailXl : 500,
valgomatUtilityM : 400,
adLabelM : 500,
toggleUtilityM : 400,
}

export const letterSpacings ={
baseTitlepieceM : "0rem",
baseTitlepieceL : "0rem",
baseBoostS : "0rem",
baseBoostM : "0rem",
baseIngressM : "0rem",
baseIngressL : "0rem",
baseHeadlineXxs : "0rem",
baseHeadlineXs : "0rem",
baseHeadlineS : "0rem",
baseHeadlineM : "0rem",
baseHeadlineL : "0rem",
baseSubheadS : "0rem",
baseSubheadM : "0rem",
baseBodyS : "0rem",
baseBodyM : "0rem",
baseCaptionS : "0rem",
baseCaptionM : "0rem",
baseMetaXs : "0rem",
baseMetaS : "0rem",
baseMetaM : "0rem",
baseMetaL : "0rem",
baseUtilityXs : "-0.0099626412551359rem",
baseUtilityS : "-0.0099626412551359rem",
baseUtilityM : "-0.0099626412551359rem",
baseTabularS : "0rem",
baseTabularM : "0rem",
titleXs : "normal",
titleS : "normal",
titleM : "normal",
titleL : "normal",
titleXl : "normal",
titleXxl : "normal",
titleCommercialXs : "-0.0099626412551359rem",
titleCommercialXxl : "-0.0099626412551359rem",
titleBreakingXs : "normal",
titleBreakingS : "normal",
titleBreakingM : "normal",
titleBreakingL : "normal",
titleBreakingXl : "normal",
titleBreakingXxl : "normal",
titleBreakingDarkXs : "normal",
titleBreakingDarkS : "normal",
titleBreakingDarkM : "normal",
titleBreakingDarkL : "normal",
titleBreakingDarkXl : "normal",
titleBreakingDarkXxl : "normal",
pretitleXs : "-0.0099626412551359rem",
pretitleS : "-0.0099626412551359rem",
pretitleM : "-0.0099626412551359rem",
pretitleL : "normal",
pretitleXl : "normal",
pretitleXxl : "normal",
pillM : "normal",
pillBoldM : "normal",
pillBoldL : "normal",
titleFeatureXs : "0rem",
titleFeatureS : "0rem",
titleFeatureM : "0rem",
titleFeatureL : "0rem",
titleFeatureXl : "0rem",
titleFeatureXxl : "0rem",
titleFeatureDarkXs : "0rem",
titleFeatureDarkS : "0rem",
titleFeatureDarkM : "0rem",
titleFeatureDarkL : "0rem",
titleFeatureDarkXl : "0rem",
titleFeatureDarkXxl : "0rem",
titleOpinionXs : "",
titleOpinionS : "normal",
titleOpinionM : "normal",
titleOpinionL : "",
titleOpinionXl : "",
titleOpinionXxl : "",
tipusM : "0rem",
avatarMetaS : "",
avatarMetaM : "",
avatarMetaL : "",
groupTitleM : "",
groupTitleL : "-0.0099626412551359rem",
valgomatHeadline1M : "0rem",
valgomatHeadline1L : "0rem",
valgomatHeadline2M : "",
valgomatHeadline2L : "",
valgomatQuestionM : "",
valgomatQuestionL : "",
valgomatDetailXs : "",
valgomatDetailS : "",
valgomatDetailM : "",
valgomatDetailL : "",
valgomatDetailXl : "",
adLabelM : "0.049813206275679506rem",
toggleUtilityM : "-0.0099626412551359rem",
}

export const lineHeights = {
baseTitlepieceM : "1.05",
baseTitlepieceL : "1.05",
baseBoostS : "1.1",
baseBoostM : "1.1",
baseIngressM : "Auto",
baseIngressL : "Auto",
baseHeadlineXxs : "1.2",
baseHeadlineXs : "1.2",
baseHeadlineS : "1.2",
baseHeadlineM : "1.2",
baseHeadlineL : "1.2",
baseSubheadS : "1.2",
baseSubheadM : "1.2",
baseBodyS : "1.4",
baseBodyM : "1.4",
baseCaptionS : "1.4",
baseCaptionM : "1.4",
baseMetaXs : "Auto",
baseMetaS : "Auto",
baseMetaM : "Auto",
baseMetaL : "Auto",
baseUtilityXs : "1.5",
baseUtilityS : "1.2",
baseUtilityM : "1.2",
baseTabularS : "Auto",
baseTabularM : "Auto",
titleXs : "1.1",
titleS : "1.1",
titleM : "1.1",
titleL : "1.1",
titleXl : "1.1",
titleXxl : "1.1",
titleCommercialXs : "1.1",
titleCommercialXxl : "1.1",
titleBreakingXs : "1.1",
titleBreakingS : "1.1",
titleBreakingM : "1.1",
titleBreakingL : "1.1",
titleBreakingXl : "1.1",
titleBreakingXxl : "1.1",
titleBreakingDarkXs : "1.1",
titleBreakingDarkS : "1.1",
titleBreakingDarkM : "1.1",
titleBreakingDarkL : "1.1",
titleBreakingDarkXl : "1.1",
titleBreakingDarkXxl : "1.1",
pretitleXs : "1.1",
pretitleS : "1.1",
pretitleM : "1.1",
pretitleL : "1.1",
pretitleXl : "1.1",
pretitleXxl : "1.1",
pillM : "1.1",
pillBoldM : "1.1",
pillL : "1.1",
pillBoldL : "1.1",
titleFeatureXs : "1.1",
titleFeatureS : "1.1",
titleFeatureM : "1.1",
titleFeatureL : "1.1",
titleFeatureXl : "1.1",
titleFeatureXxl : "1.1",
titleFeatureDarkXs : "1.1",
titleFeatureDarkS : "1.1",
titleFeatureDarkM : "1.1",
titleFeatureDarkL : "1.1",
titleFeatureDarkXl : "1.1",
titleFeatureDarkXxl : "1.1",
titleOpinionXs : "1.1",
titleOpinionS : "1.1",
titleOpinionM : "1.1",
titleOpinionL : "1.1",
titleOpinionXl : "1",
titleOpinionXxl : "1",
tipusM : "1.1",
avatarMetaS : "1.05",
avatarMetaM : "1.05",
avatarMetaL : "1.05",
groupTitleM : "1.1",
groupTitleL : "1.1",
valgomatHeadline1M : "1.25",
valgomatHeadline1L : "1.25",
valgomatHeadline2M : "1.5",
valgomatHeadline2L : "1.5",
valgomatQuestionM : "1.5",
valgomatQuestionL : "1.5",
valgomatDetailXs : "1.5",
valgomatDetailS : "1.5",
valgomatDetailM : "1.5",
valgomatDetailL : "1.5",
valgomatDetailXl : "1.5",
valgomatUtilityM : "1.5",
toggleUtilityM : "1.2",
}
