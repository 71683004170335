import { createStitches } from '@stitches/core';

import type { Theme } from '../themes/simple';

export { backgroundColors } from './background-colors';

export const { css, getCssText, createTheme, config, keyframes, globalCss } =
  createStitches({
    prefix: 'brick',
    theme: {} as Theme,
    utils: {
      px: (value: string) => ({
        paddingLeft: value,
        paddingRight: value,
      }),
      py: (value: string) => ({
        paddingTop: value,
        paddingBottom: value,
      }),
      mx: (value: string) => ({
        marginLeft: value,
        marginRight: value,
      }),
      fontstyle: (value: string) => ({
        fontFamily: `$${value}`,
        fontSize: `$${value}`,
        fontWeight: `$${value}`,
        lineHeight: `$${value}`,
        letterSpacing: `$${value}`,
      }),
    },
    media: {
      bp500: '(min-width: 500px)',
      bp532: '(min-width: 532px)',
      bp680: '(min-width: 680px)',
      bp1001: '(min-width: 1001px)',
      bpSafari: 'not all and (min-resolution:.001dpcm)',
      bp1: '(min-width: 533px) and (max-width: 1000px)',
      bp2: '(max-width: 532px)',
    },
  });

// Use this class to hide content visually, but still have it available for screen readers. sro = screen reader only
export const sroStyle = css({
  '&:not(:focus):not(:active)': {
    clip: 'rect(0 0 0 0)',
    'clip-path': 'inset(100%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    'white-space': 'nowrap',
    width: '1px',
  },
});
