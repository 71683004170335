import type { ConfigType } from '@stitches/core/types/config';

type CustomTheme = ConfigType.Theme & {
  other?: { [token in number | string]: boolean | number | string };
};

type ThemeProps<T> = {
  [K in keyof T]: K extends keyof CustomTheme
    ? T[K]
    : `{${keyof CustomTheme}: { [x: string]: string | number | boolean }}`;
};

export const createCustomTheme = <T extends CustomTheme>(
  et: ThemeProps<T> extends never ? null : ThemeProps<T>
) => et;
